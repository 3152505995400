const NAV_MENU = [
  {
    text: 'Администрирование',
    route: { name: 'AdministrationMain' },
    icon: 'adjustments',
  },
  {
    text: 'Мониторинг',
    route: { name: 'MonitoringMain' },
    icon: 'chart-bar',
  },
  {
    text: 'Клиенты',
    route: { name: 'CustomersMain' },
    icon: 'user-group',
  },
  {
    text: 'Телемаркетинг',
    route: { name: 'TelemarketingMain' },
    icon: 'speakerphone',
  },
  {
    text: 'Заявки',
    route: { name: 'RequestsMain' },
    icon: 'chat',
  },
  {
    text: 'Публикация приложений',
    route: { name: 'PublishingApplicationsMain' },
    icon: 'code-bracket',
  },
];

export default NAV_MENU;
