<template>
  <div id="widgetPhone" />
</template>

<script>
import { zadarmaWidgetFn } from '@/utils/loaderPhoneFn.js';

export default {
  name: 'WdZadarmaPhone',

  props: {
    sipKey: {
      type: String,
      required: true,
    },

    sipNumber: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.createWidget();
  },

  methods: {
    createWidget() {
      zadarmaWidgetFn(this.sipKey, this.sipNumber, 'square', 'ru', true, '{}');

      const zadarmaWPhI = document.getElementById('zdrmWPhI');
      if (zadarmaWPhI) {
        const widgetPhone = document.getElementById('widgetPhone');
        widgetPhone.appendChild(zadarmaWPhI);
      }
    },
  },
};
</script>

<style lang="scss">
#zdrmWPhI.zdrm-fixed {
  position: initial !important;
  z-index: 5;
}

.zdrm-webphone-phonenumber-container input {
  width: 186px !important;
}

.zdrm-webphone-box {
  display: block !important;
}
</style>
