<template>
  <div class="sidenav__wrapper">
    <div v-show="isOpenMenu" class="sidenav__overlay" @click="closeMenu"></div>
    <nav
      ref="sidenav"
      class="sidenav"
      :class="isOpenMenu ? 'sidenav--open' : 'sidenav--close'"
    >
      <!-- MENU HEADER -->
      <div class="sidenav__header">
        <p class="sidenav__title">Программные продукты:</p>
        <button class="sidenav__toggle-button" @click="toggleMenu">
          <sm-icon class="sidenav__toggle-icon" name="chevron-double-right" />
        </button>
      </div>
      <!-- / MENU HEADER -->

      <!-- MENU ITEMS -->
      <ul class="sidenav__list">
        <li v-for="item in menu" :key="item.text" class="sidenav__item">
          <router-link
            class="sidenav__link"
            exact-active-class="sidenav__link--active"
            :to="item.route"
            exact
            @click.native="closeMenu"
          >
            <sm-icon class="sidenav__link-icon" outline :name="item.icon" />
            <span class="sidenav__link-text">
              {{ item.text }}
            </span>
          </router-link>
        </li>
      </ul>
      <!-- / MENU ITEMS -->

      <router-link
        class="sidenav__link sidenav__link--logout"
        to=""
        @click.native="logout"
      >
        <sm-icon class="sidenav__link-icon" outline name="logout" />
        <span class="sidenav__link-text">Выход</span>
      </router-link>
    </nav>
  </div>
</template>

<script>
import navMenu from '@/constants/navMenu.js';
import SmIcon from '@/components/common/SmIcon.vue';

export default {
  name: 'SmSidenav',

  components: {
    SmIcon,
  },

  computed: {
    menu() {
      return navMenu;
    },
  },

  data() {
    return {
      isOpenMenu: false,
    };
  },

  watch: {
    isOpenMenu: {
      handler() {
        if (this.isOpenMenu) {
          document.body.style.setProperty('overflow', 'hidden');
        } else {
          document.body.style.removeProperty('overflow');
        }
      },
    },
  },

  methods: {
    toggleMenu() {
      this.isOpenMenu = !this.isOpenMenu;
    },

    closeMenu() {
      this.isOpenMenu = false;
    },

    logout() {
      this.$emit('logout');
    },
  },
};
</script>

<style lang="scss">
.sidenav {
  position: fixed;
  left: -290px;
  z-index: 99;

  width: 100%;
  max-width: 340px;
  height: 100vh;

  background-color: var(--white);
  box-shadow: 7px 7px 20px rgb(var(--rgb-blue), 9%);

  transition: left 0.5s;
}

// .sidenav .router-link-exact-active {
//   color: var(--blue);
// }

.sidenav--open {
  left: 0;
}

.sidenav__list {
  list-style: none;
}

.sidenav__header {
  padding: 13px 12px 13px 28px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #fff;
}

.sidenav__title {
  font-size: 14px;
  color: var(--gray);
}

.sidenav__toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  background-color: var(--blue);
  border-radius: 5px;
}

.sidenav__toggle-icon {
  height: 24px;
  width: 24px;

  fill: var(--white);

  transform: rotate(0deg);
  transition: transform 0.5s;
}

.sidenav--open .sidenav__toggle-icon {
  transform: rotate(180deg);
}

.sidenav__list {
  max-height: calc(100vh - 56px);
  overflow-y: auto;
}

.sidenav__link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 46px;
  // margin-bottom: 5px;
  padding-right: 15px;

  font-size: 14px;
  font-weight: 300;
  color: var(--gray);

  border: 0.5px solid rgba(var(--rgb-blue), 0.4);
  border-radius: 0 29px 29px 0;
  background: var(--white);

  transition: all 0.5s;

  &:hover {
    color: var(--blue);
  }
}

.sidenav__link--logout {
  border: none;
  border-radius: 0;
}

.sidenav--open .sidenav__link {
  justify-content: flex-start;
  padding: 3px 19px 3px 19px;
  margin: 0;

  // border-bottom: none;
  // border-radius: 0;
}

.sidenav--open .sidenav__item:last-child .sidenav__link {
  border-bottom: 0.5px solid rgba(var(--rgb-blue), 0.4);
}

.sidenav__link-text {
  display: none;

  font-size: 16px;
  font-weight: 300;
  color: var(--black);
}

.sidenav__link:hover .sidenav__link-text {
  color: var(--blue);
}

.sidenav__link--active .sidenav__link-text {
  color: var(--blue);
}

.sidenav__link--active .sidenav__link-icon {
  color: var(--blue);
}

.sidenav--open .sidenav__link-text {
  display: inline;
}

.sidenav--open .sidenav__link-icon {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.sidenav__list::-webkit-scrollbar {
  width: 3px;
}

.sidenav__list::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: var(--blue);
}

.sidenav__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 98;

  background: rgba(var(--rgb-black), 0.8);
}
</style>
