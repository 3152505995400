<template>
  <header class="header">
    <router-link class="header__reminders-box" :to="{ name: 'Reminders' }">
      <sm-icon
        class="header__reminders-icon"
        :iconColor="overdueReminders.length ? '#ec313a' : '#00a0e3'"
        iconName="Reminders"
        width="32"
        height="32"
      />
      <span class="header__reminders-count">{{ actualRemindersCount }}</span>
    </router-link>
  </header>
</template>

<script>
// vuex
import { mapState } from 'vuex';
// componets
import SmIcon from '@/components/common/icons/SmIcon.vue';

export default {
  name: 'SmHeader',

  components: {
    SmIcon,
  },

  computed: {
    ...mapState({
      reminders: (state) => state.reminders.reminders,
    }),

    actualRemindersCount() {
      return this.reminders.count || 0;
    },

    overdueReminders() {
      if (!this.reminders.items) {
        return [];
      }
      return this.reminders.items.filter((item) => {
        const currentDate = this.$moment().toISOString();
        const whenRemind = this.$moment(
          item.whenRemind,
          'DD.MM.YYYY HH:mm:ss'
        ).toISOString();
        const isBefore = this.$moment(whenRemind).isBefore(currentDate);
        if (isBefore) {
          return item;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.header {
  display: flex;
  justify-content: flex-end;
  padding: 10px 40px 10px 20px;

  border-bottom: 0.5px solid rgba(var(--rgb-blue), 0.4);
  border-radius: 0px 0px 60px 0px;
  box-shadow: 7px 7px 20px 0px #00A0E317;
}

.header__reminders-box {
  display: flex;
  position: relative;
  width: 32px;
  height: 32px;

  cursor: pointer;
}

.header__reminders-count {
  display: inline;
  z-index: 1;
  margin-top: 11px;
  width: 26px;

  color: var(--white);
  font-weight: 500;
  text-align: center;
}

.header__reminders-icon {
  position: absolute;
  z-index: 0;
}
</style>
