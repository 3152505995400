<template>
  <component
    :is="iconComponent"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    :fill="iconColor"
  />
</template>

<script>
import SmRemindersIcon from '@/components/common/icons/SmRemindersIcon.vue';

export default {
  name: 'SmIcon',

  components: {
    SmRemindersIcon,
  },

  props: {
    iconName: {
      type: String,
      required: true,
    },

    width: {
      type: [Number, String],
      required: false,
      default: 20,
    },

    height: {
      type: [Number, String],
      required: false,
      default: 20,
    },

    iconColor: {
      type: String,
      required: false,
      default: 'currentColor',
    },
  },

  computed: {
    iconComponent() {
      return `sm${this.iconName}Icon`;
    },
  },
};
</script>
