<template>
  <div class="page__main-layout">
    <sm-header />
    <sm-sidenav @logout="onLogout" />
    <main class="page__content">
      <router-view />
    </main>
    <sm-footer />
    <wd-zadarma-phone
      v-if="sipKey"
      class="widget__webphone"
      :sipKey="sipKey"
      :sipNumber="sipNumber"
    />
  </div>
</template>

<script>
// vuex
import { mapActions } from 'vuex';
// components
import SmHeader from '@/components/layouts/SmHeader.vue';
import SmSidenav from '@/components/layouts/SmSidenav.vue';
import SmFooter from '@/components/layouts/SmFooter.vue';
import WdZadarmaPhone from '@/components/widgets/WdZadarmaPhone.vue';

export default {
  name: 'Default',

  data() {
    return {
      sipKey: null,
      sipNumber: null,
    };
  },

  components: {
    SmHeader,
    SmSidenav,
    SmFooter,
    WdZadarmaPhone,
  },

  created() {
    this.generateSipKey().then(({ key, sip }) => {
      this.sipKey = key;
      this.sipNumber = sip;
    });
  },

  methods: {
    ...mapActions({
      logout: 'account/logout',
      generateSipKey: 'sip/generateSipKey',
      clearState: 'krisha/clearState',
    }),

    onLogout() {
      this.logout();
      this.clearState('sid');
      this.clearState('requests');
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style lang="scss">
.page__content {
  padding: 24px 80px;
}

.widget__webphone {
  // position: relative;
  // margin-left: auto;
  position: fixed;
  top: 24px;
  right: 80px;
}
</style>
