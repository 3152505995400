<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1872 9.68721C5.95019 9.68721 6.58179e-05 14.664 6.58179e-05 20.7816C6.58179e-05 23.2269 0.948565 25.7122 2.688 27.6384L0.231691 30.4454C-0.00999667 30.7219 -0.0686216 31.1147 0.0843157 31.4497C0.236316 31.7848 0.569565 32 0.937565 32H14.1247C19.4682 32 26.3744 27.3987 26.3744 20.7816C26.3744 14.664 20.4243 9.68721 13.1872 9.68721ZM7.49987 22.5C6.46625 22.5 5.62494 21.6586 5.62494 20.625C5.62494 19.5914 6.46631 18.75 7.49987 18.75C8.53343 18.75 9.37481 19.5914 9.37481 20.625C9.37487 21.6586 8.5335 22.5 7.49987 22.5ZM13.1872 22.5C12.1536 22.5 11.3123 21.6586 11.3123 20.625C11.3123 19.5914 12.1537 18.75 13.1872 18.75C14.2208 18.75 15.0622 19.5914 15.0622 20.625C15.0622 21.6586 14.2209 22.5 13.1872 22.5ZM18.8746 22.5C17.841 22.5 16.9997 21.6586 16.9997 20.625C16.9997 19.5914 17.841 18.75 18.8746 18.75C19.9082 18.75 20.7495 19.5914 20.7495 20.625C20.7495 21.6586 19.9082 22.5 18.8746 22.5Z"
      :fill="fill"
    />
    <path
      d="M31.7732 19.0768L29.5659 16.5014C31.1415 14.7235 31.9993 12.5555 31.9993 10.3125C31.9993 4.62616 26.5319 0 19.8121 0C13.7356 0 8.573 3.78689 7.66143 8.71581C9.35693 8.13968 11.2584 7.81217 13.1873 7.81217C21.3969 7.81217 28.1417 13.5464 28.2403 20.6249H31.0618C31.8627 20.625 32.2927 19.682 31.7732 19.0768Z"
      :fill="fill"
    />
    <path
      d="M7.49987 22.5C6.46625 22.5 5.62494 21.6586 5.62494 20.625C5.62494 19.5914 6.46631 18.75 7.49987 18.75C8.53343 18.75 9.37481 19.5914 9.37481 20.625C9.37487 21.6586 8.5335 22.5 7.49987 22.5Z"
      :fill="fill"
    />
    <path
      d="M13.1872 22.5C12.1536 22.5 11.3123 21.6586 11.3123 20.625C11.3123 19.5914 12.1537 18.75 13.1872 18.75C14.2208 18.75 15.0622 19.5914 15.0622 20.625C15.0622 21.6586 14.2209 22.5 13.1872 22.5Z"
      :fill="fill"
    />
    <path
      d="M18.8746 22.5C17.841 22.5 16.9997 21.6586 16.9997 20.625C16.9997 19.5914 17.841 18.75 18.8746 18.75C19.9082 18.75 20.7495 19.5914 20.7495 20.625C20.7495 21.6586 19.9082 22.5 18.8746 22.5Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  nmae: 'SmRemindersIcon',

  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
